import React from 'react'
import logo from '../logo.svg';
import { Typography } from 'antd';

const { Title } = Typography;

export const About = () => {
 return (
   <div style={{ textAlign: 'center' }}>
    <Title>About Gorilla Math</Title>
    <img src={logo} alt="Gorilla Math Logo" width={200} />
    <p>A few useful E-commerce calculators and converters to save time.</p>
   </div>
 )
}

export default About;