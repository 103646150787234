import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Sidebar from './Components/Layout/Sidebar';
import 'antd/dist/antd.css';
import './App.css';
import { Layout } from 'antd';
import asyncComponent from './Components/AsyncComponent';
import About from './pages/About';
import AcronymGenerator from './AcronymGenerator';
const AsyncProductProfitCalcIndex = asyncComponent(() => import("./ProductProfitCalc"));
const AsyncDiscountPriceCalcIndex = asyncComponent(() => import("./DiscountPriceCalc"));
const AsyncShopifyDiscountUrlGenerator = asyncComponent(() => import("./ShopifyDiscountUrlGenerator"));
const AsyncFacebookAudienceFinder = asyncComponent(() => import("./FacebookAudienceFinder"));
const AsyncFacebookAdInterestSuggestion = asyncComponent(() =>
  import("./FacebookAdInterestSuggestion")
);
const { Content, Footer } = Layout;

export default class App extends Component {
  render() {
    return (
      <Router>
        <Layout>
          <Sidebar />
          <Layout style={{ marginLeft: 30 }}>
            <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
              <div style={{ padding: 24, background: "#fff" }}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    component={AsyncDiscountPriceCalcIndex}
                  />
                  <Route
                    path="/product-profit"
                    component={AsyncProductProfitCalcIndex}
                  />
                  <Route
                    path="/shopify-discount-url-generator"
                    component={AsyncShopifyDiscountUrlGenerator}
                  />
                  <Route
                    path="/facebook-audience-finder"
                    component={AsyncFacebookAudienceFinder}
                  />
                  <Route
                    path="/facebook-ad-interest-suggestion"
                    component={AsyncFacebookAdInterestSuggestion}
                  />
                  <Route
                    path="/acronym-generator"
                    component={AcronymGenerator}
                  />
                  <Route path="/about-us" component={About} />
                </Switch>
              </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              &copy; {new Date().getFullYear()} Gorilla Math |{" "}
              <Link to="/about-us">About</Link>
            </Footer>
          </Layout>
        </Layout>
      </Router>
    );
  }
}