import React, { Component } from 'react';
import logo from '../../logo.svg';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
const { Sider } = Layout;
const { SubMenu } = Menu;

const shopLogo = () => (<svg viewBox="0 0 150.4 170.7"><path d="M173.3,165.9S154.8,40.7,154.7,39.8a2.62,2.62,0,0,0-.53-.77l-.12-.12A2.93,2.93,0,0,0,153,38.3c-.6,0-13.7-1-13.7-1s-9.1-9.1-10.2-10a1.94,1.94,0,0,0-1.3-.6v.17a6,6,0,0,0-2.39.13c-.1,0-2,.6-5.1,1.6-3.1-8.9-8.4-17-17.9-17h-.9c-2.6-3.4-6-5-8.8-5-22,0-32.6,27.5-35.9,41.5-8.6,2.7-14.7,4.5-15.4,4.8-4.8,1.5-4.9,1.6-5.5,6.1-.5,3.4-13,100.1-13,100.1l97.3,18.2.3-.07v.07ZM100.8,33.4v1c-5.4,1.7-11.1,3.4-17,5.3,3.3-12.6,9.6-18.8,14.8-21.1h0C99.9,22,100.8,26.8,100.8,33.4Zm-8.6-21a4.78,4.78,0,0,1,2.8,1C87.9,16.7,80.4,25,77.3,41.8c-4.7,1.5-9.2,2.8-13.5,4.2C67.4,33.2,76.4,12.4,92.2,12.4ZM96,86.8a30.08,30.08,0,0,0-12.7-3.1c-10.3,0-10.8,6.5-10.8,8.1,0,8.8,23,12.2,23,32.9,0,16.3-10.3,26.8-24.2,26.8-16.8,0-25.2-10.4-25.2-10.4l4.5-14.8s8.8,7.6,16.2,7.6a6.57,6.57,0,0,0,6.9-6.6c0-11.5-18.8-12-18.8-31C54.9,80.4,66.3,65,89.4,65c8.6-.1,13,2.5,13,2.5Zm10.7-54.2V30.8a38.41,38.41,0,0,0-2-13.3c5,.6,8.1,6.1,10.2,12.5C112.5,30.7,109.6,31.6,106.7,32.6Z" transform="translate(-22.9 -6.6)"/></svg>);
const ShopifyIcon = props => <Icon component={shopLogo} {...props} />;


export default class Sidebar extends Component {
  constructor(props) {
    super(props)
   
    this.state = {
      collapsed: false
    }
  }

  render() {
    return (
      <Sider
        style={{ height: '100vh', }}
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={this.state.collapsed}
        onCollapse={(collapsed, type) => { this.setState({collapsed: !this.state.collapsed}); }}
        width="280"
      >
        <div className="logo">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['0']}
          defaultOpenKeys={['sub0']}
          onClick={this.handleClick}
        >
          <SubMenu
            key="sub0"
            title={
              <span>
                <Icon type="calculator" />
                <span>Product Calculators</span>
              </span>
            }
          >
            <Menu.Item key="0">
              <Link to="/">
                Discount Calculator
              </Link>
            </Menu.Item>
            <Menu.Item key="1">
              <Link to="/product-profit">
                Profit Calculator
              </Link>
            </Menu.Item>          
          </SubMenu>
          <SubMenu
            key="sub1"
            title={
              <span>
                <ShopifyIcon style={{fill:'#fff'}}/>
                <span>Shopify</span>
              </span>
            }
          >
            <Menu.Item key="2">
              <Link to="/shopify-discount-url-generator">
              Discount Link Generator
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/acronym-generator">
                Acronym/Sku Generator
              </Link>
            </Menu.Item>              
          </SubMenu>        
        </Menu>
      </Sider>
    )
  }
}
