import React, { useState } from 'react';
import { Alert, Button, Input, Card, Checkbox } from "antd";
const { TextArea } = Input;

const AcronymGenerator = () => {

  const [data,setData] = useState([])
  const [prefix,setPrefix] = useState('')
  const [suffix,setSuffix] = useState('')
  const [output,setOutput] = useState('')
  const [duplicate, setDuplicate] = useState(false);
  const [unique, setUnique] = useState(true)

  function toggleUnique(e) {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked === true) {
      setUnique(true)
    } else {
      setUnique(false);
    }
  }

  const rand = () => {
    return Math.floor(1000 + Math.random() * 9000);
  }  

  const generateAcronyms = () => {
    if (data === undefined || data === null || data.length === 0) {
      return
    } else {
      let arr = data.split('\n');    
      multilineAcronym(arr, prefix, suffix);
    }
  }

  const acronym = (str, prefix = ``, suffix = ``) => {
    if (str.length === 0) {
      return ` `
    }
    let clean = str.replace(/'/g, )
    let matches = clean.match(/\b(\w)/g)
    let acronym = matches.join(``)
    if (unique) {
      return `${prefix}${acronym.toUpperCase()}-${rand(3)}${suffix.length === 0 ? ``: `-`}${suffix}`;
    } else {
      return `${prefix}${acronym.toUpperCase()}${suffix}`;  
    }
  }

  const multilineAcronym = (arr, prefix = ``, suffix = ``) => {
    let acronymArr = []
    for (let i = 0; i < arr.length; i++) {
      acronymArr.push(acronym(arr[i],`${prefix}`,`${suffix}`))
      acronymArr.push('\n')
    }

    // duplicate detection
    const set = new Set(acronymArr)
    const hasDuplicates = set.size < acronymArr.length;
    if (!unique) {
      setDuplicate(hasDuplicates);
    }
    return setOutput(acronymArr)
  }  

  return (
    <div>
      <Card title="Acronym/Sku Generator">
        <Input
          addonBefore="Prefix"
          onChange={(e) => setPrefix(e.target.value)}
        />
        <br />
        <br />
        <Input
          addonBefore="Suffix"
          onChange={(e) => setSuffix(e.target.value)}
        />
        <br />
        <Checkbox onChange={toggleUnique} defaultChecked>
          Make Unique
        </Checkbox>
        <br />
        <TextArea
          placeholder="Enter text separated by newline..."
          rows={4}
          autoSize={{ minRows: 5, maxRows: 10 }}
          onChange={(e) => setData(e.target.value)}
        />
        <br />
        <br />
        <Button type="primary" onClick={(e) => generateAcronyms()}>
          Generate
        </Button>

        <div style={{ marginTop: 16 }}>
          {duplicate && (
            <Alert
              style={{ marginBottom: 16 }}
              message="One or more SKU contains duplicate"
              type="warning"
              showIcon
            />
          )}
          <p style={{ whiteSpace: "pre-line" }}>{output}</p>
        </div>
      </Card>
    </div>
  );
}

export default AcronymGenerator;